// eslint-disable-next-line
import { UserLayout, BasicLayout, BlankLayout, PageView, RouteView } from '@/layouts'

export const asyncRouterMap = [
  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: 'menu.home' },
    redirect: '/permission/operator',
    children: [
      {
        path: '/point',
        name: 'point',
        // redirect: '/order/list',
        component: RouteView,
        meta: { title: '主菜单', icon: 'inbox', keepAlive: true, roles: ['admin', 'customer', 'operator', 'marketer'] },
        children: [
              {
                path: '/permission/operator',
                name: 'Operators',
                component: () => import('@/views/permission/operator/List'),
                meta: { title: '用户管理', keepAlive: true, icon: 'user',roles: ['admin'] }
              },
              {
                hidden: true,
                path: '/permission/operator/detail',
                name: 'OperatorsDetail',
                component: () => import('@/views/permission/operator/Detail'),
                meta: { title: '用户管理详情', keepAlive: true, icon: 'user',roles: ['admin'] }
              },
              {
                path: '/points/order',
                name: 'pointsOrderList',
                component: () => import('@/views/points/order/List'),
                meta: { title: '订单积分', keepAlive: true, icon: 'table',roles: ['admin'] }
              },
              {
                hidden: true,
                path: '/points/order/detail',
                name: 'pointsManagerDetail',
                component: () => import('@/views/points/order/Detail'),
                meta: { title: '订单积分详情', keepAlive: true, icon: 'table',roles: ['admin'] }
              },
              {
                path: '/branch',
                name: 'Branch',
                component: () => import('@/views/branch/List'),
                meta: { title: '批量兑换', keepAlive: true, icon: 'table',roles: ['admin'] }
              },
              {
                hidden: true,
                path: '/branch/detail',
                name: 'BranchDetail',
                component: () => import('@/views/branch/Detail'),
                meta: { title: '批量兑换详情', keepAlive: true, icon: 'table',roles: ['admin'] }
              },
              {
                path: '/points/jiangli/',
                name: 'pointsJiangLiList',
                component: () => import('@/views/points/jiangli/List'),
                meta: { title: '奖励积分', keepAlive: true, icon: 'table',roles: ['admin'] }
              },
              {
                hidden: true,
                path: '/points/jiangli/edit',
                name: 'pointsJiangLiEdit',
                component: () => import('@/views/points/jiangli/Edit'),
                meta: { title: '奖励积分详情', keepAlive: true, icon: 'table',roles: ['admin'] }
              },

              {
                path: '/points/manager/List',
                name: 'managerList',
                component: () => import('@/views/points/manager/List'),
                meta: { title: '管理奖积分', keepAlive: true, icon: 'user-add',roles: ['admin'] }
              },
              {
                hidden: true,
                path: '/points/manager',
                name: 'pointsManagerList',
                component: () => import('@/views/points/manager/ManagerList'),
                meta: { title: '管理积分列表', keepAlive: true, icon: 'user-add',roles: ['admin'] }
              },
              {
                hidden: true,
                path: '/points/server',
                name: 'pointsServerList',
                component: () => import('@/views/points/manager/ServerList'),
                meta: { title: '服务积分列表', keepAlive: true, icon: 'user-add',roles: ['admin'] }
              },

              {
                path: '/points/share',
                name: 'pointsShareList',
                component: () => import('@/views/points/share/List'),
                meta: { title: '份额管理', keepAlive: true, icon: 'pie-chart',roles: ['admin'] }
              },
              {
                hidden: true,
                path: '/points/share/edit',
                name: 'pointsShareLiEdit',
                component: () => import('@/views/points/share/Edit'),
                meta: { title: '份额详情', keepAlive: true, icon: 'table',roles: ['admin'] }
              },
              {
                path: '/purpose',
                name: 'purposeList',
                component: () => import('@/views/purpose/List'),
                meta: { title: '目标管理', keepAlive: true, icon: 'flag',roles: ['admin'] }
              },
              {
                path: '/production/manage',
                name: 'productionManageList',
                component: () => import('@/views/production/manage/List'),
                meta: { title: '商品管理', keepAlive: true, icon: 'inbox',roles: ['admin'] }
              },
              {
                hidden: true,
                path: '/production/manage/edit',
                name: 'productionManageEdit',
                component: () => import('@/views/production/manage/Edit'),
                meta: { title: '新增商品', keepAlive: true, icon: 'inbox',roles: ['admin'] }
              },
              {
                name: 'MiniAdvertise',
                path: '/operation/mini',
                component: () => import('@/views/operation/mini/List'),
                meta: { title: '广告管理', keepAlive: true,icon: 'picture', roles: ['admin', 'operator'], permission: [] }
              },
              {
                hidden: true,
                path: '/operation/mini/edit',
                name: 'MiniAdvertiseEdit',
                component: () => import('@/views/operation/mini/Edit'),
                meta: { title: '编辑广告', keepAlive: true, roles: ['admin', 'operator'], permission: [] }
              },
          ]
      },

      //////////************************************************
      {
        hidden: true,
        path: '/exception',
        name: 'exception',
        component: RouteView,
        redirect: '/exception/403',
        meta: { title: 'menu.exception', icon: 'warning', permission: ['exception'] },
        children: [
          {
            path: '/exception/403',
            name: 'Exception403',
            component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/403'),
            meta: { title: 'menu.exception.not-permission', permission: ['exception'] }
          },
          {
            path: '/exception/404',
            name: 'Exception404',
            component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404'),
            meta: { title: 'menu.exception.not-find', permission: ['exception'] }
          },
          {
            path: '/exception/500',
            name: 'Exception500',
            component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/500'),
            meta: { title: 'menu.exception.server-error', permission: ['exception'] }
          }
        ]
      },
      // account
      {
        hidden: true,
        path: '/account',
        component: RouteView,
        redirect: '/account/center',
        name: 'account',
        meta: { title: 'menu.account', icon: 'user', keepAlive: true, permission: ['user'] },
        children: [
          {
            path: '/account/center',
            name: 'center',
            component: () => import('@/views/account/center'),
            meta: { title: 'menu.account.center', keepAlive: true, permission: ['user'] }
          },
          {
            path: '/account/settings',
            name: 'settings',
            component: () => import('@/views/account/settings/Index'),
            meta: { title: 'menu.account.settings', hideHeader: true, permission: ['user'] },
            redirect: '/account/settings/basic',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/account/settings/basic',
                name: 'BasicSettings',
                component: () => import('@/views/account/settings/BasicSetting'),
                meta: { title: 'account.settings.menuMap.basic', hidden: true, permission: ['user'] }
              },
              {
                path: '/account/settings/security',
                name: 'SecuritySettings',
                component: () => import('@/views/account/settings/Security'),
                meta: {
                  title: 'account.settings.menuMap.security',
                  hidden: true,
                  keepAlive: true,
                  permission: ['user']
                }
              },
              {
                path: '/account/settings/custom',
                name: 'CustomSettings',
                component: () => import('@/views/account/settings/Custom'),
                meta: { title: 'account.settings.menuMap.custom', hidden: true, keepAlive: true, permission: ['user'] }
              },
              {
                path: '/account/settings/binding',
                name: 'BindingSettings',
                component: () => import('@/views/account/settings/Binding'),
                meta: { title: 'account.settings.menuMap.binding', hidden: true, keepAlive: true, permission: ['user'] }
              },
              {
                path: '/account/settings/notification',
                name: 'NotificationSettings',
                component: () => import('@/views/account/settings/Notification'),
                meta: {
                  title: 'account.settings.menuMap.notification',
                  hidden: true,
                  keepAlive: true,
                  permission: ['user']
                }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]
// 用于权限管理
export const addRouters = JSON.parse(JSON.stringify(asyncRouterMap))

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register')
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult')
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined
      }
    ]
  },

  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  }
]
