import axios from 'axios'

export function timeFix () {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome () {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent () {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function handleScrollHeader (callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function () {}
  window.addEventListener(
    'scroll',
    event => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}

export function isIE () {
  const bw = window.navigator.userAgent
  const compare = (s) => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate (id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}
export function scorePassword (pass) {
  let score = 0
  if (!pass) {
    return score
  }
  // award every unique letter until 5 repetitions
  const letters = {}
  for (let i = 0; i < pass.length; i++) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1
      score += 5.0 / letters[pass[i]]
  }

  // bonus points for mixing it up
  const variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass)
  }

  let variationCount = 0
  for (var check in variations) {
      variationCount += (variations[check] === true) ? 1 : 0
  }
  score += (variationCount - 1) * 10

  return parseInt(score)
}

export function getUserTypeName (type) {
  let name = ""
  switch (type) {
    case 'normal':
      name = '会员'
      break;
    case 'store':
      name = '云店'
      break;
    case 'center':
      name = '服务中心'
      break;
    case 'president':
      name = '运营总裁'
      break;
    default:
      break;
  }
  return name
}


export function getSpreaderMobileList(mobile, type, spreaderType) {
  type = parseInt(type || 0);
  const spreaderMobileList = [];
  if (mobile) {
    const len = mobile.length;
    let type0 = 0;
    switch (spreaderType) {
      case "store":
        type0 = 1;
        break;
      case "center":
        type0 = 2;
        break;
      case "president":
        type0 = 3;
        break;
      default:
        type0 = 0;
        break;
    }
    spreaderMobileList.push({
      color: "#E62734",
      text: type0 > 0 ? mobile.substr(0, type0) : ""
    });
    spreaderMobileList.push({
      color: "",
      text: mobile.substr(type0, len - type - type0)
    });
    spreaderMobileList.push({
      color: "#1890ff",
      text: type > 0 ? mobile.substr(len - type, type) : ""
    });
  }
  return spreaderMobileList
}


export function isVideoUrl(url) {
  return url.search(/.avi$|.rmvb$|.rm$|.asf$|.divx$|.mpg$|.mpeg$|.mpe$|.wmv$|.mp4$|.mkv$|vob$/i) >= 0
}

//用于把文件下载到本地,主要解决下载图片问题
export function getDownload(url, name) {
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url,
      responseType: "blob"
    })
      .then(data => {
        console.log(data);
        let url = window.URL.createObjectURL(new Blob([data.data]));
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute(
          "download",
          name ? name : url.split("/")[url.split("/").length - 1]
        ); // 自定义下载文件名（如exemple.txt）
        document.body.appendChild(link);
        link.click();
        resolve();
      })
      .catch(error => {
        console.log(error);
        reject(error.toString());
      });
  });
}