<template>
 <span >
         <span style="margin-right: 20px">
          <a-badge v-if="dataSize>0" :count="dataSize" @click="handleToTongZhi">
            <a-avatar style="background-color: #EEEEEE" :size="32">
              <a-icon type="bell"/>
            </a-avatar>
          </a-badge>
           <a-badge v-if="dataSize<=0"  @click="handleToTongZhi">
            <a-avatar style="background-color: #EEEEEE" :size="32">
              <a-icon type="bell"/>
            </a-avatar>
          </a-badge>
        </span>
</span>
</template>

<script>
import { getUnreadMessage  } from '@/api/inside'
export default {
  name: 'TongZhi',
  data(){
    return {
      allMessage: {}, //消息集合
      dataSize:0,
      idelTime:60*1000
    }
  },
  props: {
  },
  created() {
    this.handleGetGetMessage();
  },
  methods: {
    handleToTongZhi () {
      this.$router.push({ path: '/member/TongZhi' })
    },
    handleGetGetMessage(){
      //默认先调用一次
      this.getUnread();
      setTimeout(() => {
        //自动调用
        this.getUnread();
        this.handleGetGetMessage();
      }, this.idelTime)
    },
    getUnread(){
      // getUnreadMessage({}).then(result => {
      //   this.dataSize = result.unreadCount;
      // }).catch((error)=>{console.log(error);})
    },
     getUserIP (onNewIP) {
         let MyPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
         let pc = new MyPeerConnection({
           iceServers: []
         });
         let noop = () => {
         };
         let localIPs = {};
         let ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;
         let iterateIP = (ip) => {
           if (!localIPs[ip]) onNewIP(ip);
           localIPs[ip] = true;
         };
         pc.createDataChannel('');
         pc.createOffer().then((sdp) => {
           sdp.sdp.split('\n').forEach(function (line) {
             if (line.indexOf('candidate') < 0) return;
             line.match(ipRegex).forEach(iterateIP);
           });
           pc.setLocalDescription(sdp, noop, noop);
         }).catch((reason) => {
         });
         pc.onicecandidate = (ice) => {
           if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;
           ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
         };
       }
  }
}
</script>

<style lang="less" scoped>
.ant-pro-drop-down {
  /deep/ .action {
    margin-right: 8px;
  }
  /deep/ .ant-dropdown-menu-item {
    min-width: 160px;
  }
}
</style>
